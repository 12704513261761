<template>
    <div class="userDownload">
        <!-- <div class="userDownload_tab">
            <div @click="type = 1,list = [],page=1,listFun()" :class="type == 1?'active':''">字体素材</div>
            <div @click="type = 2,list = [],page=1,listFun()" :class="type == 2?'active':''">变形宝典</div>
        </div> -->
        <div class="userDownload_box" v-if="type == 1">
            <div class="userDownload_list clearfix" v-if="list.length>0">
                <template v-for="(item,index) in list">
                    <!-- wow animate__animated animate__fadeInUp -->
                    <div class="fl userDownload_li " :style="{animationDelay:0.1*index+'s'}" :key="index" @click="toDetail(item.material_id)" v-if="item.material">
                        <div class="userDownload_li_box">
                            <img v-lazy="item.material.thumb" :key="item.material.thumb" alt="">
                        </div>
                    </div>
                </template>
                
            </div>
            <el-empty v-else description="暂无数据"></el-empty>
            <page v-if="total>12" :page="page" :limit="limit" :total="total"></page>
        </div>
        <div class="search" v-if="type == 2">
            <div class="search_list clearfix" v-if="list.length>0">
                <div class="fl search_item" v-for="(item,index) in list" :key="index" :ref="'getRightRefs'+index" @mouseenter="rightIfFun(index)" @mouseleave="activeIndex = -1">
                    <img v-lazy="item.material.thumb" :key="item.material.thumb" alt="">

                    <div class="search_item_big" v-if="activeIndex == index">
                        <div class="search_item_big_box">
                            <img v-lazy="item.material.thumb" :key="item.material.thumb" alt="">

                            <div class="search_item_download" @click="downFun(item.id)"></div>
                            <div class="search_item_add" @click="jionFun(item.id)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <el-empty v-else description="暂无数据"></el-empty>
            <page v-if="total>12" :page="page" :limit="limit" :total="total" ref="page"></page>
        </div>
    </div>
</template>

<script>
// import { WOW } from 'wowjs'
import page from '../../components/page.vue'
export default {
    name:'userDownload',
    components:{
        page
    },
    data(){
        return{
            type:1,//tab分类
            list:[],

            searchList:[],
            // 分页
            total:0,//总数
            page:1,//页数
            limit:12,//每页个数

            activeIndex:-1,
            showDirection:false
        }
    },
    mounted(){
        this.$parent.tab = 2;
        this.$parent.tabIndex = 3;
        // new WOW().init();
        this.$parent.$parent.routerIndex = 0;
        this.$parent.$parent.showIndex = 1;
        this.page = 1;
        if(this.$refs.page){
            this.$refs.page.currentPage = 1;
        }
        this.listFun()
        localStorage.removeItem('clearAll')
    },

    methods:{
        listFun(){
            var that = this;
            this.list = [];
            this.$api.POST(this.$face.download_recordIndex,{
                type: this.type,
                user_id: this.$parent.userId,
                page:this.page,
                limit:this.limit
            },function(res){
                that.list = res.data.list;
                that.total = res.data.total;
                that.$forceUpdate
                console.log(that.list)
            })
        },

        //加入清单
        jionFun(id){
            var that = this;
            var params ={
                material_id: id,
                type: 2,
            }
            this.$api.POST(this.$face.download_cartJoin,params,function(res){
                that.$utile.prompt('success',res.msg);
            })
        },
        // 下载源文件
        downFun(id){
            var that = this;
            var params ={
                id: id,
            }
            this.$api.POST(this.$face.downloadDown,params,function(res){
                if(res.data.down_code == 100){
                    that.$message({
                        message: res.data.msg,
                        type: "error",
                    });
                    return ;
                }else{
                    const a = document.createElement('a')
                    //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
                    a.href = res.data.down_url // 完整的url则直接使用
                    // document.body.appendChild(a)
                    a.click()
                    that.$utile.prompt('success',res.data.msg);
                }
            })
        },

        // 获取距离右侧距离
        rightIfFun(i){
            console.log(this.$refs['getRightRefs'+i][0].offsetLeft)
            if(window.innerWidth - this.$refs['getRightRefs'+i][0].offsetLeft <= 503){
                this.showDirection = true;
            }else{
                this.showDirection = false
            }
            this.activeIndex = i;
        },

        //查看详情
        toDetail(id){
            localStorage.setItem('clearAll',2) 
            this.$router.push({name:'materialDetail',query:{id:id}})
        }
    },
    //使用keepAlive缓存组件状态，页面切换路由会触发 activated 钩子函数。在 activated 中将 之前记录的位置赋值给引起滚动条滚动元素的 scrollTop
    activated(){
        console.log(this.scroll)
        this.$nextTick(() => {
            this.$parent.tab = 2;
            this.$parent.tabIndex = 3;
            // new WOW().init();
            this.$parent.$parent.routerIndex = 0;
            this.$parent.$parent.showIndex = 1;
            if(localStorage.getItem('clearAll') != 2){
                this.page = 1;
                if(this.$refs.page){
                    this.$refs.page.currentPage = 1;
                }
                this.listFun()
            }
            localStorage.removeItem('clearAll')
        });
    }
}
</script>

<style scoped>
.userDownload{
    padding-top: 48px;
}
.userDownload_tab{
    margin-bottom: 31px;
    display: flex;
    align-items: center;
}
.userDownload_tab div{
    width: 100px;
	height: 40px;
    line-height: 40px;
    text-align: center;
	background-color: #ffffff;
	border-radius: 10px;
    font-size: 15px;
	color: #999999;
    margin-right: 33px;
    cursor: pointer;
}
.userDownload_tab div.active{
    background-color: #5957FF;
    color: #FFFFFF;
}


.userDownload_box{
    min-height: 400px;
    padding-bottom: 54px;
}
/* 专题 */
.userDownload_list{
    margin: 34px -13.33px 45px;
}
.userDownload_li{
    margin:0 13.33px 30px;
		width: 330px;
		height: 218px;
		overflow: hidden;
		border-radius: 10px;
}
.userDownload_li_box{
    width: 330px;
    height: 218px;
    
    overflow: hidden;
    position: relative;
		transition: all .3s ease-in-out;
}
.userDownload_li_box img{
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
  width: 330px;
	height: auto;
}
.userDownload_li:hover .userDownload_li_box{transform: scale(1.08);} 



.search{
    width: 100%;
    min-height: 400px;
    padding-bottom: 54px;
}
.search_list{
    margin-bottom: 28px;
}
.search_item{
    width: 133px;
    height: 133px;
    background-color: #FFFFFF;
	border-radius: 10px;
	border: solid 1px #dddddd;
    box-sizing: border-box;
    position: relative;
}
.search_item:not(:last-child){
    margin-right: 7px;
}
.search_item img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: 0.3s;
}
.search_item:hover img{
    transform: scale(1.08)
}
.search_item_big{
    width: 370px;
    height: 350px;
    position: absolute;
    top: -31px;
    left: 133px;
    z-index: 10;
    padding-left: 20px;
    box-sizing: border-box;
}
.search_item_big.left{
    left: auto;
    right: 133px;
    padding-left: 0;
    padding-right: 20px;
}
.search_item_big_box{
    width: 350px;
	height: 350px;
	background-color: #ffffff;
	box-shadow: 1px 1px 24px 0px 
		rgba(0, 0, 0, 0.09);
	border-radius: 10px;
    position: relative;
}
.search_item_download{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 15px;
    right: 14px;
    background: url(../../assets/images/baodianxiazai.png) no-repeat;
    background-size: 100% 100%;
}
.search_item_add{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 65px;
    right: 14px;
    background: url(../../assets/images/addqingdan.png) no-repeat;
    background-size: 100% 100%;
}
</style>